<template>
  <v-container fluid class="px-8">
    <v-toolbar height="50" class="mb-6 mt-2" elevation="4" rounded>
      <CalendarMenu />
    </v-toolbar>

      <TripCalendar
        v-if="active == 'trip'"
        :blockedDateEvents="blockedDateEvents"
        :specialDateEvents="specialDateEvents"
        :loading="loading"
      />
      <VehicleCalendar
        v-if="active == 'vehicle'"
        :blockedDateEvents="blockedDateEvents"
        :specialDateEvents="specialDateEvents"
        :loading="loading"
      />
      <DriverCalendar
        v-if="active == 'driver'"
        :blockedDateEvents="blockedDateEvents"
        :specialDateEvents="specialDateEvents"
        :loading="loading"
      />

  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { GET_CALENDAR_TRIP_REQUESTS, GET_CALENDAR_ASSIGNMENTS } from '@/store/modules/Calendar/actions.js';
import CalendarMenu from './Menu.vue'
import TripCalendar from './TripCalendar.vue';
import VehicleCalendar from './VehicleCalendar.vue';
import DriverCalendar from './DriverCalendar.vue';

export default {
  components: { TripCalendar, VehicleCalendar, DriverCalendar, CalendarMenu },
  inject: ['eventHub'],
  created() {
    this.eventHub.$on('refreshCalendars', () => this.fetchItems());
  },
  beforeDestroy() {
    this.eventHub.$off('refreshCalendars');
  },
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters('user', ['me']),
    ...mapGetters('app', ['tripCalendar', 'vehicleCalendar', 'driverCalendar']),
    ...mapGetters('calendar', ['calendarTripRequests', 'calendarAssignments']),
    ...mapGetters('config', ['calendarConfig', 'blockedDates', 'specialDates']),
    active() {
      if (this.vehicleCalendar.active) return 'vehicle';
      else if (this.driverCalendar.active) return 'driver';
      else return 'trip';
    },
    blockedDateEvents() {
      return this.blockedDates.map((e) => ({
        name: 'Blocked Date',
        id: e.id,
        allDay: true,
        start: e.begin,
        end: e.end,
        timed: false,
        color: 'red accent-2',
        dateData: {
          type: 'blocked',
          tripTypeIds: e.tripTypeIds,
          tripEventIds: e.tripEventIds,
          description: e.description,
        },
      }));
    },
    specialDateEvents() {
      return this.specialDates.map((e) => ({
        name: 'Special Date',
        id: e.id,
        allDay: true,
        start: e.begin,
        end: e.end,
        timed: false,
        color: 'deep-purple ',
        dateData: {
          type: 'special',
          tripTypeIds: e.tripTypeIds,
          description: e.description,
        },
      }));
    },
    showTripCalendar() {
      return (
        (this.calendarConfig.showTripCalendar.includes(9) && this.me.is.approver) ||
        this.me.roles.map((e) => e.roleId).some((r) => this.calendarConfig.showTripCalendar.includes(r))
      );
    },
    showVehicleCalendar() {
      return (
        (this.calendarConfig.showVehicleCalendar.includes(9) && this.me.is.approver) ||
        this.me.roles.map((e) => e.roleId).some((r) => this.calendarConfig.showVehicleCalendar.includes(r))
      );
    },
    showDriverCalendar() {
      return (
        (this.calendarConfig.showDriverCalendar.includes(9) && this.me.is.approver) ||
        this.me.roles.map((e) => e.roleId).some((r) => this.calendarConfig.showDriverCalendar.includes(r))
      );
    },
  },
  methods: {
    ...mapActions('calendar', [GET_CALENDAR_TRIP_REQUESTS, GET_CALENDAR_ASSIGNMENTS]),
    async fetchItems() {
      this.loading = true;
      await this.getCalendarTripRequests();
      await this.getCalendarAssignments();
      this.loading = false;
    },
  },
  watch: {
    $route: {
      handler: function (value) {
        if (value.path == '/calendars') this.fetchItems();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style>
.v-calendar .v-event {
  white-space: normal !important;
  height: fit-content !important;
}

.event-icons > i {
  font-size: 18px;
  padding: 0px 6px 0px 6px;
  color: rgb(240, 237, 237) !important;
}
</style>
