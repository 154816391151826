<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="114">
        <v-row dense v-if="loading">
          <v-col cols="12">
            <div class="text-caption text-center">Loading trips...</div>
          </v-col>
          <v-col cols="12" class="mb-5">
            <v-progress-linear indeterminate color="primary"></v-progress-linear>
          </v-col>
        </v-row>
        <v-row dense v-else>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-select
              :value="tripCalendar.locationFilter"
              :items="locations"
              item-text="name"
              item-value="id"
              label="Location"
              outlined
              clearable
              dense
              @change="setFilter($event, 'locationFilter')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :value="tripCalendar.tripTypeFilter"
              :items="tripTypes"
              item-text="name"
              item-value="id"
              label="Trip Type"
              outlined
              clearable
              dense
              @change="setFilter($event, 'tripTypeFilter')"
            ></v-select>
          </v-col>
          <v-col cols="2">
            <v-select
              :value="tripCalendar.tripEventFilter"
              :items="tripEventItems"
              :item-text="getTripEventText"
              item-value="id"
              label="Trip Event"
              outlined
              clearable
              dense
              @change="setFilter($event, 'tripEventFilter')"
            ></v-select>
          </v-col>
          <v-col cols="1">
            <v-select
              :value="tripCalendar.zoneFilter"
              :items="zones"
              label="Zone"
              outlined
              clearable
              dense
              @change="setFilter($event, 'zoneFilter')"
            ></v-select>
          </v-col>
          <v-spacer></v-spacer>
        </v-row>
        <v-toolbar flat class="tbar">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday"> Today </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small> mdi-chevron-left </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small> mdi-chevron-right </v-icon>
          </v-btn>
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip bottom contained color="#fff" class="ma-0 pa-0" :nudge-top="-10" tooltip-opacity="1">
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="primary" v-bind="attrs" v-on="on" class="mx-2 button-chip">
                <v-icon left class="mr-2 blue--text">mdi-information</v-icon>
                <span class="blue--text">Color Codes</span>
              </v-btn>
            </template>
            <div class="mx-2">
              <p class="font-weight-bold green--text text--darken-1">Approved & Assigned</p>
              <p class="font-weight-bold blue--text text--darken-2">Approved</p>
              <p class="font-weight-bold orange--text text--darken-3">Pending Approval</p>
              <p class="font-weight-bold amber--text text--darken-1">
                Waiting on Quote/Changes Requested (Not Submitted)
              </p>
            </div>
          </v-tooltip>
          <v-menu bottom right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                <span>{{ typeToLabel[tripCalendar.type] }}</span>
                <v-icon right> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleTypeChange('day')">
                <v-list-item-title>Day</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleTypeChange('week')">
                <v-list-item-title>Week</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleTypeChange('month')">
                <v-list-item-title>Month</v-list-item-title>
              </v-list-item>
              <v-list-item @click="handleTypeChange('4day')">
                <v-list-item-title>4 days</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet height="950">
        <v-calendar
          ref="calendar"
          :value="tripCalendar.focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="tripCalendar.type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        >
          <!-- <template v-slot:event="{ event }">
            <div class=" event-icons d-flex flex-wrap mt-2 ">
              <v-icon color="deep-purple-darken-1" v-if="event.batch">mdi-autorenew</v-icon>
              <v-icon color="deep-purple-darken-1" v-if="event.recurrence"> mdi-autorenew </v-icon>
              <v-icon color="deep-purple-darken-1" v-if="event.splitTrip"> mdi-swap-horizontal </v-icon>
              <v-icon color="deep-purple-darken-1" v-if="event.wasRescheduled"> mdi-calendar </v-icon>
              <v-icon color="deep-purple-darken-1" v-if="event.awayForLunch"> mdi-food-apple </v-icon>
              <v-icon color="deep-purple-darken-1" v-if="event.haveHealthConcerns"> mdi-medical-bag </v-icon>
              <v-icon color="deep-purple-darken-1" v-if="event.outOfState"> mdi-weather-night </v-icon>
              <v-icon color="deep-purple-darken-1" v-if="event.needExternalTransportation">
                mdi-train-car
              </v-icon>
              <v-icon color="deep-purple-darken-1" v-if="event.canAssign && event.pendingDrivers">
                mdi-account-alert
              </v-icon>
              <v-icon color="deep-purple-darken-1" v-if="event.canAssign && event.pendingVehicles">
                mdi-bus-alert
              </v-icon>
            </div>
            <p>{{ event.name }}</p>
          </template>-->
        </v-calendar>
        <v-menu v-model="selectedOpen" :close-on-content-click="false" :activator="selectedElement" offset-x>
          <v-card v-if="selectedEvent.tripData" color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title class="w-full">
                Trip #{{ selectedEvent.id }}
                <span class="right">{{ selectedEvent.id ? status(selectedEvent.id).display : '' }}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="pa-0 ma-0 grey lighten-4 mb-2" dense>
                <template v-slot:default>
                  <tbody class="font-weight-medium">
                    <tr v-for="(value, propertyName) in selectedEvent.tripData" :key="propertyName + value">
                      <td>{{ propertyName }}</td>
                      <td>{{ value }}</td>
                      <v-divider></v-divider>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-label class="mt-2">Current Vehicle Reservations</v-label>
              <v-simple-table class="pa-0 ma-0 grey lighten-4" dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Vehicle</th>
                      <th class="text-left">Vehicle Location</th>
                      <!-- <th class="text-left">Vehicle Status</th> -->
                      <th class="text-left">Driver</th>
                    </tr>
                  </thead>
                  <tbody class="font-weight-medium">
                    <tr v-for="assignment in selectedEvent.assignments" :key="assignment.id">
                      <td>{{ getVehicle(assignment) }}</td>
                      <td>{{ getVehicleLocation(assignment.vehicleId) || '-' }}</td>
                      <td>{{ getDriver(assignment) }}</td>
                      <v-divider></v-divider>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <v-btn class="ma-2" color="error" @click="selectedOpen = false"> Close </v-btn>
              <v-btn
                v-show="selectedEvent.tripData && canViewTrip(selectedEvent.id)"
                class="ma-2"
                color="primary"
                @click="$router.push(`/trip-request/${selectedEvent.id}?calendar=true`)"
              >
                View
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card v-if="selectedEvent.dateData" color="grey lighten-4" min-width="350px" flat>
            <v-toolbar :color="selectedEvent.color" dark>
              <v-toolbar-title class="w-full">
                {{ selectedEvent.dateData.type == 'blocked' ? 'Blocked Date' : 'Special Date' }}
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-simple-table class="pa-0 ma-0 grey lighten-4" dense>
                <template v-slot:default>
                  <tbody class="font-weight-medium">
                    <tr>
                      <td>Description</td>
                      <td>
                        {{ selectedEvent.dateData.description }}
                      </td>
                      <v-divider></v-divider>
                    </tr>
                    <tr>
                      <td>Trip Types Effected</td>
                      <td>
                        {{ selectedEvent.dateData.tripTypeIds.map((e) => tripTypesById[e].name).join(', ') }}
                      </td>
                      <v-divider></v-divider>
                    </tr>
                    <tr v-if="selectedEvent.dateData.tripEventIds">
                      <td>Trip Events Effected</td>
                      <td>
                        {{ selectedEvent.dateData.tripEventIds.map((e) => tripEventsById[e]?.name).join(', ') }}
                      </td>
                      <v-divider></v-divider>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-card-actions class="justify-space-between">
              <v-btn class="ma-2" color="error" @click="selectedOpen = false"> Close </v-btn>
              <v-btn
                v-show="selectedEvent.tripData"
                class="ma-2"
                color="primary"
                @click="$router.push(`/trip-request/${selectedEvent.id}?calendar=true`)"
              >
                View
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { add, sub, getMonth, getYear } from 'date-fns';
import { getVehicleLocation, todayString, months, calculateDateShift } from '@/util';
import VEHICLE_TYPES from '@/shared/types';

export default {
  props: {
    blockedDateEvents: Array,
    specialDateEvents: Array,
    loading: Boolean,
  },
  data: () => ({
    getVehicleLocation,
    typeToLabel: {
      month: 'Month',
      week: 'Week',
      day: 'Day',
      '4day': '4 Days',
    },
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    VEHICLE_TYPES,
  }),
  mounted() {
    this.$refs.calendar.checkChange();
    this.$refs.calendar.move(0);
  },
  computed: {
    ...mapGetters('calendar', ['calendarTripRequests']),
    ...mapGetters('tripRequest', ['tripRequests', 'tripRequestsById']),
    ...mapGetters('vehicleType', ['vehicleTypesById']),
    ...mapGetters('tripType', ['tripTypesById', 'tripTypes']),
    ...mapGetters('tripEvent', ['tripEventsById', 'tripEvents']),
    ...mapGetters('additionalTransportation', ['additionalTransportationsById']),
    ...mapGetters('location', ['locationsById', 'locations', 'zones']),
    ...mapGetters('destination', ['destinationsById']),
    ...mapGetters('driver', ['driversById']),
    ...mapGetters('vehicle', ['vehiclesById']),
    ...mapGetters('config', ['tripRequestConfig', 'specialIndicators', 'blockedDates', 'specialDates']),
    ...mapGetters('user', ['usersById']),
    ...mapGetters('app', ['tripCalendar']),
    events() {
      let filteredTrips = [...this.calendarTripRequests];
      if (this.tripCalendar.locationFilter)
        filteredTrips = filteredTrips.filter((e) => e.locationId == this.tripCalendar.locationFilter);
      if (this.tripCalendar.tripTypeFilter)
        filteredTrips = filteredTrips.filter((e) => e.tripTypeId == this.tripCalendar.tripTypeFilter);
      if (this.tripCalendar.tripEventFilter)
        filteredTrips = filteredTrips.filter((e) => e.tripEventIds.includes(this.tripCalendar.tripEventFilter));
      if (this.tripCalendar.zoneFilter)
        filteredTrips = filteredTrips.filter((e) => this.zone(e) == this.tripCalendar.zoneFilter);

      filteredTrips = filteredTrips
        .filter((e) => e.status > -2)
        .map((trip) => {
          const location = this.locationsById[trip.locationId];
          const destination = this.destinationsById[trip.destinationId];
          const tripType = this.tripTypesById[trip.tripTypeId];

          // Check if the location, destination, and tripType are defined
          if (!location || !destination || !tripType) {
            return null; // Skip this event
          }

          const event = {
            name: `Trip #${trip.id} \u2023 ${location.abbr} \u2023 ${destination.name} \u2023 ${
              tripType.name
            } \u2023 ${trip.tripEventIds.map((e) => this.tripEventsById[e]?.name).join(', ')}`,
            id: trip.id,
            allDay: false,
            start: this.formatDateForCalendar(trip.leaveDate, trip.leaveTime),
            end: this.formatDateForCalendar(trip.returnDate, trip.returnTime),
            timed: true,
            color: this.status(trip).color,
            /* Used to render icons
        ------------------------------------*/
            // batch: trip.batchId,
            // recurrence: trip.recurrence.length,
            // splitTrip: trip.splitTrip,
            // wasRescheduled:
            //   trip.auditHistory.findIndex((e) => e.description.includes('Rescheduled')) >= 0 ? true : false,
            // awayForLunch: trip.awayForLunch && trip.needLunch,
            // haveHealthConcerns: trip.haveHealthConcerns,
            // outOfState: trip.outOfState,
            // needExternalTransportation: trip.needExternalTransportation,
            // canAssign: trip.canAssign,
            // pendingVehicles: trip.pendingVehicles,
            // pendingDrivers: trip.pendingDrivers,
            /*----------------------------------*/
            tripData: {
              Leave: this.readableDate(trip.leaveDate, trip.leaveTime),
              Return: this.readableDate(trip.returnDate, trip.returnTime),
              'Trip Type / Event(s)': `${this.tripTypesById[trip.tripTypeId].name} / ${trip.tripEventIds
                .map((e) => this.tripEventsById[e]?.name)
                .join(', ')}`,
              'Requesting Location': `${this.locationsById[trip.locationId].name} (${
                this.locationsById[trip.locationId].code
              })`,
              Destination: this.destinationsById[trip.destinationId].name,
              Teacher: `${trip.teacherName} (Phone: ${trip.teacherPhone})`,
              'Requested By':
                trip.submittedUser && this.usersById[trip.submittedUser]
                  ? this.usersById[trip.submittedUser].displayName
                  : '-',
              Zone: this.zone(trip),
              Comments: trip.comments,
              '# Students / # Adults': `${trip.totalStudents} / ${trip.totalAdults}`,
              'Dropoff Only': 'Yes',
              'Pickup Only': 'Yes',
            },
            assignments: trip.assignments,
          };
          if (!trip.dropoffOnly) delete event.tripData['Dropoff Only'];
          if (!trip.pickupOnly) delete event.tripData['Pickup Only'];
          return event;
        })
        .filter((event) => event !== null);

      return [...this.blockedDateEvents, ...this.specialDateEvents, ...filteredTrips];
    },

    tripEventItems() {
      if (!this.tripTypeFilter) return this.tripEvents;
      return this.tripEvents.filter((e) => e.tripTypeId == this.tripTypeFilter);
    },
    title() {
      const currentFocus = this.tripCalendar.focus ? this.tripCalendar.focus : todayString();
      return `${months[getMonth(new Date(currentFocus))]} ${getYear(new Date(currentFocus))}`;
    },
  },
  methods: {
    ...mapMutations('app', ['setTripCalendar']),
    displayDestinations(trip) {
      return trip.stops
        .map((s) => s.name)
        .toString()
        .split(',')
        .join('  >>> ');
    },
    displayDepartureName(trip) {
      return trip.stops[1] ? trip.stops[0].name : '';
    },
    formatDateForCalendar(date, time) {
      const [year, month, day] = date.split('-');
      const [hours, minutes] = time.split(':');
      return new Date(+year, +month - 1, +day, +hours, +minutes);
    },
    readableDate(date, time) {
      const [year, month, day] = date.split('-');
      const d = new Date(`${month}-${day}-${year}`).toLocaleDateString('en-us', {
        weekday: 'short',
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      });
      const s = time.split(':');
      const hour = String(s[0] > 12 ? Number(s[0]) - 12 : s[0]);
      const minute = s[1];
      const ampm = s[0] >= 12 ? 'PM' : 'AM';
      return `${d} @ ${hour}:${minute} ${ampm}`;
    },
    zone(trip) {
      if (this.tripRequestConfig && this.tripRequestConfig.other) {
        if (this.tripRequestConfig.other.determineZoneBy == 'request') return this.locationsById[trip.locationId].zone;
        if (this.tripRequestConfig.other.determineZoneBy == 'vehicle') {
          const reserveFromLocation = this.locationsById[trip.locationId].vehicleOrder[0] || 0;
          return this.locationsById[reserveFromLocation].zone;
        }
      }
      return '-';
    },
    vehicles(trip) {
      if (this.vehicleTypesById && this.additionalTransportationsById) {
        const addT = {
          3: 'Approved Charter',
          2: 'Contractor',
          1: 'Rental / Dealership',
        };
        if (trip.vehicleType === this.VEHICLE_TYPES.NORMAL)
          return `${trip.numVehicles} (${this.vehicleTypesById[trip.vehicleTypeId].name})`;
        else if (trip.vehicleType !== this.VEHICLE_TYPES.NORMAL) {
          return `${trip.numVehicles} (${addT[trip.vehicleType]} - ${
            trip.additionalTransportationId && this.additionalTransportationsById[trip.additionalTransportationId]
              ? this.additionalTransportationsById[trip.additionalTransportationId].name
              : 'None Selected'
          })`;
        } else return 'No vehicles requested';
      } else return '-';
    },
    status(trip) {
      if (typeof trip === 'number') trip = this.calendarTripRequests.find((e) => e.id == trip);
      if (trip.approval.approved) {
        if (trip.canAssign && (trip.pendingDrivers || trip.pendingVehicles))
          return { display: 'Approved', color: 'blue darken-2' };
        else return { display: 'Approved', color: 'green darken-1' };
      } else if (trip.approval.awaitingApproval) {
        let tooltip = trip.approval.awaitingApproval.primaryApprovers
          ? trip.approval.awaitingApproval.primaryApprovers.map((e) => `${e.userDisplayName} (${e.userEmail})`)
          : [];
        tooltip = tooltip.join('<br>');
        return {
          display: 'Pending - ' + trip.approval.awaitingApproval.name,
          tooltip,
          color: 'orange darken-3',
        };
      } else if (
        (trip.vehicleType == this.VEHICLE_TYPES.APPROVED_CHARTER ||
          trip.vehicleType == this.VEHICLE_TYPES.CONTRACTOR) &&
        !trip.additionalTransportationId
      )
        return { display: 'Waiting on Quote', color: 'amber darken-1' };
      else if (trip.status == -2) return { display: 'Denied', color: 'red accent-2' };
      else if (trip.status == -3) return { display: 'Canceled', color: 'red accent-2' };
      else return { display: 'Changes Requested', color: 'amber darken-1' };
    },
    canViewTrip(tripRequestId) {
      return this.tripRequestsById[tripRequestId] && this.tripRequestsById[tripRequestId].permissions.canView;
    },
    getDriver(item) {
      if (item.driver) return item.driver;
      else if (item.driverId)
        return `${this.driversById[item.driverId].firstName} ${this.driversById[item.driverId].lastName}`;
      else return '-';
    },
    getVehicle(item) {
      if (item.vehicle) return item.vehicle;
      else if (item.vehicleId) return this.vehiclesById[item.vehicleId].name;
      else return '-';
    },
    viewDay({ date }) {
      this.setTripCalendar({ ...this.tripCalendar, type: 'day', focus: date });
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.setTripCalendar({ ...this.tripCalendar, focus: '' });
    },
    prev() {
      const currentFocus = this.tripCalendar.focus ? this.tripCalendar.focus : todayString();
      const focus = sub(new Date(currentFocus), calculateDateShift(this.tripCalendar.type));
      this.setTripCalendar({ ...this.tripCalendar, focus });
    },
    next() {
      const currentFocus = this.tripCalendar.focus ? this.tripCalendar.focus : todayString();
      const focus = add(new Date(currentFocus), calculateDateShift(this.tripCalendar.type));
      this.setTripCalendar({ ...this.tripCalendar, focus });
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    handleTypeChange(type) {
      this.setTripCalendar({ ...this.tripCalendar, type });
    },
    updateRange() {},
    setFilter(val, filter) {
      const obj = { ...this.tripCalendar };
      obj[filter] = val;
      this.setTripCalendar(obj);
    },
    getTripEventText(e) {
      return `${e.name} (${this.tripTypesById[e.tripTypeId].name})`;
    },
  },
};
</script>

<style scoped>
.w-full {
  width: 100%;
}
.right {
  float: right;
}
.tbar {
  margin-top: -24px;
}
.v-tooltip__content {
  background-color: #fff !important;
  border: 1px solid #c9c6c6 !important;
  border-radius: 8px !important;
  padding: 8px !important;
  opacity: 1 !important;
}
</style>
