<template>
  <v-toolbar-items class="w-full">
    <v-btn
      v-if="showTripCalendar"
      text
      :color="active == 'trip' ? 'primary' : ''"
      class="nav-button text-capitalize"
      @click="showCalendar('trip')"
    >
      Trip Calendar
    </v-btn>
    <v-btn
      v-if="showVehicleCalendar"
      text
      :color="active == 'vehicle' ? 'primary' : ''"
      class="nav-button text-capitalize"
      @click="showCalendar('vehicle')"
    >
      Vehicle Calendar
    </v-btn>
    <v-btn
      v-if="showDriverCalendar"
      text
      :color="active == 'driver' ? 'primary' : ''"
      class="nav-button text-capitalize"
      @click="showCalendar('driver')"
    >
      Driver Calendar
    </v-btn>
    <v-spacer></v-spacer>
    <v-btn
      v-if="showTripCalendar || showVehicleCalendar || showDriverCalendar"
      text
      class="nav-button text-capitalize"
      @click="refresh()"
    >
      <v-icon left>mdi-refresh</v-icon>
      Refresh
    </v-btn>
  </v-toolbar-items>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'CalendarMenu',
  inject: ['eventHub'],
  computed: {
    ...mapGetters('app', ['tripCalendar', 'vehicleCalendar', 'driverCalendar']),
    ...mapGetters('config', ['calendarConfig']),
    ...mapGetters('user', ['me']),
    active() {
      if (this.tripCalendar.active) return 'trip';
      if (this.vehicleCalendar.active) return 'vehicle';
      if (this.driverCalendar.active) return 'driver';
      if (this.showTripCalendar) return 'trip';
      if (this.showVehicleCalendar) return 'vehicle';
      if (this.showDriverCalendar) return 'driver';
      return '';
    },
    showTripCalendar() {
      return (
        (this.calendarConfig.showTripCalendar.includes(9) && this.me.is.approver) ||
        this.me.roles.map((e) => e.roleId).some((r) => this.calendarConfig.showTripCalendar.includes(r))
      );
    },
    showVehicleCalendar() {
      return (
        (this.calendarConfig.showVehicleCalendar.includes(9) && this.me.is.approver) ||
        this.me.roles.map((e) => e.roleId).some((r) => this.calendarConfig.showVehicleCalendar.includes(r))
      );
    },
    showDriverCalendar() {
      return (
        (this.calendarConfig.showDriverCalendar.includes(9) && this.me.is.approver) ||
        this.me.roles.map((e) => e.roleId).some((r) => this.calendarConfig.showDriverCalendar.includes(r))
      );
    },
  },
  mounted() {
    if (!this.showTripCalendar && !this.showVehicleCalendar && !this.showDriverCalendar) this.$router.push('/');
    if (!this.tripCalendar.active && !this.vehicleCalendar.active && !this.driverCalendar.active)
      if (this.showTripCalendar) this.setTripCalendar({ ...this.tripCalendar, active: true });
      else if (this.showVehicleCalendar) this.setVehicleCalendar({ ...this.vehicleCalendar, active: true });
      else if (this.showDriverCalendar) this.setDriverCalendar({ ...this.driverCalendar, active: true });
  },
  methods: {
    ...mapMutations('app', ['setTripCalendar', 'setVehicleCalendar', 'setDriverCalendar']),
    showCalendar(cal) {
      this.setTripCalendar({ ...this.tripCalendar, active: cal == 'trip' });
      this.setVehicleCalendar({ ...this.vehicleCalendar, active: cal == 'vehicle' });
      this.setDriverCalendar({ ...this.driverCalendar, active: cal == 'driver' });
    },
    refresh() {
      this.eventHub.$emit('refreshCalendars');
    },
  },
};
</script>

<style scoped>
.nav-button {
  margin: 0;
}
.w-full {
  width: 100%;
}
</style>
